import React from "react";
import axios from "axios";

import Footer from "./footer";
import Header from "./header";
import Mobile from "./mobile";

import "bootstrap/dist/css/bootstrap.min.css"

// Custom Style
import "../assets/custom-style.css"

const Layout: React.FC = ({ children }: any) => {

  // Add a request interceptor
  axios.interceptors.request.use(function (request) {
    // Do something before request is sent
    // if (localStorage.getItem('token_register')) {
    //   request.headers['Authorization'] = `Bearer ${localStorage.getItem('token_register')}`;
    // } else {
    //   if (localStorage.getItem('token')) {
    //     request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    //   }
    //   if (localStorage.getItem('api_key')) {
    //     request.headers['X-Signature'] = `${localStorage.getItem('api_key')}`;
    //   }
    // }
    return request;
  }, function (error) {
      // Do something with request error
      return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
  }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
  });

  return (
    <>
      {/* header-area start */}
      <Header></Header>
      {/* header-area end */}

      {/* mobile-menu-area start */}
      <Mobile></Mobile>  
		  {/* mobile-menu-area end */}

      {children}

      {/* footer-area start */}
      <Footer></Footer>
      {/* footer-area end */}
    </>
  );
};

export default Layout;
