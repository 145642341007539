import { Link } from "gatsby-link";
import React from "react";

const Header: React.FC = () => {
  return (
    <>
      <header className="header-area">
        {/* <div className="header-top d-none d-md-inline">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="top-social">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                    <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8">
                <div className="top-srch-bx">
                  <input type="text" placeholder="Text Search" />
                  <div className="button_search">
                    <button title="Search" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <div className="account-login">
                  <div className="accounts-login">
                    <a href="#"><span>Login</span></a>
                    <div className="login-form">
                      <form action="#">
                        <div className="form_wrapper_body">
                          <p className="login-username">
                            <label>
                              User or Email
                            </label>
                            <input type="text" placeholder="User or Email" /> 
                          </p>
                          <p className="login-password">
                            <label>
                              Password
                            </label>
                            <input type="text" placeholder="Password" />
                          </p>
                        </div>
                        <div className="form_wrapper_footer">
                          <input className="button" type="submit" value="Login" />
                          <a className="button" href="#">Register</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="contacts-top">
                  <div className="contacts-supports">
                    <span className="spprt-phone">
                      <i className="fa fa-phone"></i>
                      01973833508
                    </span>
                    <span className="support-email">
                      <i className="fa fa-envelope"></i>
                      admin@bootexperts.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="header-bottom" id="sticker">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="logo">
                  <a href="/"><img src="/img/logo_blue.png" alt="" /></a>
                </div>
              </div>
              <div className="col-md-9 d-none d-md-inline">
                <div className="main-menu">
                  <nav>
                    <ul>
                      <li>
                          <Link to="/1000xinnovation" style={{textDecoration: 'none'}}>1000x Innovation</Link>
                          <ul className="submenu">
                              <li><a href="http://bit.ly/cararegis-1000x" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>Panduan Pendaftaran</a></li>
                              <li><a href="http://bit.ly/1000x-guidebook" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>Guidebook</a></li>
                              <li><a href="http://bit.ly/1000x-structureguide" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>Struktur Presentasi</a></li>
                          </ul>
                      </li>
                      <li>
                          <Link to="/about" style={{textDecoration: 'none'}}>Tentang Kami</Link>
                          <ul className="submenu">
                              <li><Link to="/about#latarbelakang" style={{textDecoration: 'none'}}>Latar belakang</Link></li>
                              <li><Link to="/about#trustees" style={{textDecoration: 'none'}}>Trustees</Link></li>
                              <li><Link to="/about#leadership" style={{textDecoration: 'none'}}>Leadership</Link></li>
                              <li><Link to="/about#team" style={{textDecoration: 'none'}}>Team</Link></li>
                              <li><Link to="/about#visi" style={{textDecoration: 'none'}}>Visi</Link></li>
                              <li><Link to="/about#misi" style={{textDecoration: 'none'}}>Misi</Link></li>
                              <li><Link to="/about#whymarvinfoundation" style={{textDecoration: 'none'}}>Why Marvin Foundation</Link></li>
                          </ul>
                      </li>
                      <li>
                          <Link to="/program" style={{textDecoration: 'none'}}>Program</Link>
                          <ul className="submenu">
                              <li><Link to="/program#webinar" style={{textDecoration: 'none'}}>Future Leader Webinar</Link></li>
                              <li><Link to="/program#scholarship" style={{textDecoration: 'none'}}>Future Leader Scholarship</Link></li>
                              <li><Link to="/program#bootcamp" style={{textDecoration: 'none'}}>Future Leader Bootcamp</Link></li>
                              <li><Link to="/program#basecamp" style={{textDecoration: 'none'}}>Future Leader Basecamp</Link></li>
                              <li><Link to="/program#marvincare" style={{textDecoration: 'none'}}>Marvin Care</Link></li>
                              <li><Link to="/program#youngdigipreneur" style={{textDecoration: 'none'}}>Young Digipreneur</Link></li>
                          </ul>
                      </li>
                      <li>
                          <Link to="/publication" style={{textDecoration: 'none'}}>Publikasi</Link>
                          <ul className="submenu">
                              <li><Link to="/publication#compro" style={{textDecoration: 'none'}}>Company Profile</Link></li>
                              <li><Link to="/publication#news" style={{textDecoration: 'none'}}>Berita Terbaru</Link></li>
                              <li><Link to="/publication#feed" style={{textDecoration: 'none'}}>Instagram Feed</Link></li>
                              <li><Link to="/publication#article" style={{textDecoration: 'none'}}>Artikel</Link></li>
                          </ul>
                      </li>
                      <li>
                          <a href="javascript:">Get Involved</a>
                          <ul className="submenu">
                              <li><Link to="/donate" style={{textDecoration: 'none'}}>Donasi</Link></li>
                              <li><Link to="/join-us" style={{textDecoration: 'none'}}>Lowongan</Link></li>
                          </ul>
                      </li>
                      <li><Link to="/contact-us" style={{textDecoration: 'none'}}>Kontak</Link></li>
                      {/* <li className="parent">
                        <a href="#" style={{textDecoration: 'none'}}>Pages</a>
                        <div className="mega-menu">
                          <span>
                            <a className="mm-title" href="#">page set 1</a>
                            <Link to="/shop/cart">Cart</Link>
                            <Link to="/shop/left-sidebar">Shop left sidebar</Link>
                            <Link to="/shop/right-sidebar">Shop right sidebar</Link>
                            <Link to="/shop/list">Shop list</Link>
                            <Link to="/shop/full-width">Shop fullwidth</Link>
                          </span>
                          <span>
                            <a className="mm-title" href="#">page set 2</a>
                            <Link to="/blog-detail">Blog details</Link>
                            <Link to="/shop/checkout">Checkout</Link>
                            <Link to="/gallery">Gallery</Link>
                            <Link to="/my-account">My account</Link>
                          </span>
                          <span>
                            <a className="mm-title" href="#">page set 3</a>
                            <Link to="/causes">Causes</Link>
                            <Link to="/portofolio">Portfolio</Link>
                            <Link to="/services">Services</Link>
                            <Link to="/single-products">Single product</Link>
                            <Link to="/team-member">Team member</Link>
                            <Link to="/shop/wishlist">Wishlist</Link>
                            <Link to="/404">404</Link>
                          </span>
                        </div>
                      </li>   */}
                      {/* <li>{<a href="#"><span className="donate-tdy">Donate Today!</span></a>}</li> */}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;
