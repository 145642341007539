import { withPrefix } from "gatsby";
import { Script } from "gatsby-script";
import React, { useState } from "react";

const Footer: React.FC = () => {

  const [loadedJQuery, setLoadedJQuery] = useState(false);
  const [loadedJQueryUI, setLoadedJQueryUI] = useState(false);
  const [loadedNivo, setLoadedNivo] = useState(false);
  const [loadedWow, setLoadedWow] = useState(false);
  const [loadedOwl, setLoadedOwl] = useState(false);
  const [loadedVenobox, setLoadedVenobox] = useState(false);
  const [loadedMixitup, setLoadedMixitup] = useState(false);
  const [loadedElevate, setLoadedElevate] = useState(false);
  const [loadedMeanmenu, setLoadedMeanmenu] = useState(false);

  return (
    <>
      <footer className="footer-area">
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                <div className="copyryt-txt">
                  Copyright ©{" "}
                  <a className="text-white" href="https://marvinfoundation.org">marvinfoundation.org</a> . All Rights Reserved
                </div>
              </div>
              {/* <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                <div className="footer-menu">
                  <nav>
                    <ul>
                      <li><a href="#">FAQ</a></li>
                      <li><a href="#">Our Story</a></li>
                      <li><a href="#">Be a Volunteer</a></li>
                      <li><a href="#">What we do</a></li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <Script
        src={withPrefix('https://code.jquery.com/jquery-2.2.4.min.js')}
        integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="
        crossOrigin="anonymous"
        onLoad={() => setLoadedJQuery(true)}
      />
      <Script
        src={withPrefix('https://code.jquery.com/ui/1.13.2/jquery-ui.min.js')}
        integrity="sha256-lSjKY0/srUM9BE3dPm+c4fBo1dky2v27Gdjm2uoZaL0="
        crossOrigin="anonymous"
        onLoad={() => setLoadedJQueryUI(true)}
      />

      {loadedJQuery && loadedJQueryUI && (<>
        {/*  all js here  */}
        {/*  wow js  */}
        <Script src="/js/wow.min.js" onLoad={() => setLoadedWow(true)}/>
        {/*  owl.carousel js  */}
        <Script src="/js/owl.carousel.min.js" onLoad={() => setLoadedOwl(true)}/>
        {/*  venobox js  */}
        <Script src="/js/venobox.min.js" onLoad={() => setLoadedVenobox(true)}/>
        {/*  mixitup js  */}
        <Script src="/js/jquery.mixitup.js" onLoad={() => setLoadedMixitup(true)}/>
        {/*  elevateZoom js  */}
        <Script src="/js/jquery.elevateZoom-3.0.8.min.js" onLoad={() => setLoadedElevate(true)}/>
        {/*  nivo-slider js  */}
        <Script src="/lib/js/jquery.nivo.slider.js" onLoad={() => setLoadedNivo(true)}/>
        {loadedNivo && <Script src="/lib/home.js" strategy="idle"/>}
        {/*  meanmenu js  */}
        <Script src="/js/jquery.meanmenu.js" strategy="idle" onLoad={() => setLoadedMeanmenu(true)}/>
        {/*  plugins js  */}
        <Script src="/js/plugins.js" strategy="idle"/>
        {/*  main js  */}
        {loadedWow && loadedOwl && loadedVenobox && loadedMixitup && loadedElevate && loadedMeanmenu && <Script src="/js/main.js" strategy="idle"/>}
      </>
      )}
    </>
  );
};

export default Footer;
